import { pt } from 'vuetify/lib/locale'

export default {
  ...pt,
  title: 'Glitch Exp',
  login: 'Entrar',
  logout: 'Sair',
  heading_welcome: 'Bem-vindo ao EXP',
  home_para1: 'GLITCH EXP é uma ferramenta desenvolvida para a Twitch Interactive para uso em ',
  rewards: 'campanhas de recompensas',
  home_para1_1: ' em que os criadores podem ganhar pontos. Quanto mais pontos os criadores ganham, melhor é a recompensa que eles recebem. A maneira de ganhar pontos pode variar de acordo com cada campanha e pelos critérios estabelecidos pela Twitch.',
  home_para1_2: 'Por favor leia atentamente a ',
  home_para1_3: ' e nos contate caso tenha qualquer questionamento.',
  reminder: 'Lembrete',
  home_para2: 'Por favor garanta que você já se inscreveu para participar na campanha e forneceu seus dados, como username da Twitch no formulário de inscrição. Quando você entrar no website, você verá a campanha para a qual você está inscrito e participando.<br/><br/><br/>',
  terms: 'Termos de Serviço',
  privacy_policy: 'Política de Privacidade',
  develop_by: 'Essa ferramenta foi desenvolvida em colaboração com o X-Team e outros parceiros de desenvolvimento.',
  level: 'Nível',
  completed: 'Completo!',
  congratulations: 'Parabéns!',
  challenge_completed: 'Desafio Completo!',
  campaign_expired: 'A campanha expirou!',
  copy_clipboard: 'A overlay Exp foi copiado com sucesso para a área de transferência!',
  open_campaigns: 'Campanhas Abertas',
  campaign_name: 'Nome da Campanha',
  campaign_level: 'Nível<br />Concluído',
  campaign_level_completed: 'Nível Concluído',
  campaign_score: 'Pontuação',
  campaign_details: 'Detalhes',
  campaign_no_details: '&nbsp;',
  campaign_rewards: 'Coupons',
  campaign_no_rewards: '&nbsp;',
  campaign_start_date: 'Data de Início',
  campaign_end_date: 'Data Final',
  campaign_time_left: 'Tempo Restante',
  campaign_includes: 'Inclui',
  current_score: 'Pontuação atual',
  completed_level: 'Nível Concluído',
  next_level_score: 'Pontuação do Próximo Nível',
  browser_source_url: 'Barra EXP',
  browser_source_help: 'Clique em sua versão preferida da Barra EXP para copiá-la e cole-a no sistema OBS usando a URL fornecida.',
  no_campaigns_available: 'Nenhuma campanha disponível',
  closed_campaigns: 'Campanhas Fechadas',
  search_rewards: 'Pesquisar Coupons',
  rewards_per_page: 'Coupons por Página',
  no_rewards_available: 'Nenhuma recompensa disponível',
  tier: 'Nível',
  coupon_code: 'Código do Cupom',
  digital_reward_name: 'Nome da Recompensa',
  digital_reward_mechanics: 'Mecânica da Recompensa',
  digital_mechanics: 'Mecânica da <br/>Recompensa',
  download_rewards: 'Baixar Coupons',
  welcome_to: 'Bem-vindo ao',
  sign_up: 'Inscrever-se',
  sign_up_incomplete: 'Cadastro incompleto',
  landing_title: 'Se increva e participe do desafio!',
  landing_subtitle: 'As seguintes informações serão usadas nesse desafio:',
  landing_affiliate: 'Afiliado',
  landing_partner: 'Parceiro',
  landing_full_name: 'Nome completo',
  landing_email: 'Email',
  landing_contact: 'Telefone',
  landing_country: 'País',
  landing_address: 'Endereço',
  landing_address_info: '*Esse endereço será utilizado para confirmação de residência no Brasil e/ou para entrega de premiação em caso de recompensas físicas.',
  landing_state: 'Estado',
  landing_city: 'Cidade',
  landing_marketing: 'Apenas para os propósitos de Marketing, a Twitch pode vir a utilizar sua imagem e seu canal na Twitch em nossos canais de comunicação. Você autoriza a Twitch ao uso de sua imagem e do seu canal em video/clips ou lives para publicidade?',
  landing_attention: 'Atenção: a Twitch se reserva no direito de remover qualquer publicidade no caso de violação das Diretrizes da nossa comunidade.',
  landing_agree: 'Eu concordo.',
  landing_disagree: 'Eu discordo.',
  landing_submit: 'Me inscrever',
  landing_success: 'Inscrição confirmada.',
  landing_click_here: 'Clique aqui',
  landing_success_info: 'para acessar o site Glitch EXP e se prepare para ativar sua participação no desafio',
  landing_error: 'Desculpe, mas sua inscrição não é elegível.',
  landing_error_info: 'Esse programa de incentivo à Parceiros da Twitch é apenas para convidados. Vamos considerar seu interesse em futuros programas.',
  unauthorized_message: 'Desculpe, você não tem acesso no momento.',
  dashboard_unused_overlay: 'Por favor, use a Barra EXP no OBS!'
}
