import { es } from 'vuetify/lib/locale'

export default {
  ...es,
  title: 'Glitch Exp',
  login: 'Acesso',
  logout: 'Cerrar Sesión',
  heading_welcome: 'Bienvenido a EXP',
  home_para1: 'GLITCH EXP es una herramienta desarrollada para Twitch Interactive para su uso en campañas que ',
  rewards: 'recompensa a los creadores',
  home_para1_1: ' en función de los puntos que ganan. Cuantos más puntos ganes, mayor será la recompensa que recibes. La forma en que gana puntos variará según cada campaña y los criterios determinados por Twitch.',
  home_para1_2: 'Lea la ',
  home_para1_3: ' y contáctenos si tiene alguna pregunta.<br/><br/>',
  reminder: 'Recordatorio',
  home_para2: 'Asegúrese de que ya se haya registrado para participar en una campaña y de haber introducido su nombre de usuario Twitch en el formulario relevante. Cuando ingrese al sitio web, solo verá la campaña en la que participa.<br/><br/><br/>',
  terms: 'Términos de Servicio',
  privacy_policy: 'Política de Privacidad',
  develop_by: 'Esta herramienta se desarrolló junto con X-Team y otros socios de desarrollo.',
  level: 'Nivel',
  completed: '¡Completado!',
  congratulations: '¡Enhorabuena!',
  challenge_completed: '¡Desafío Completado!',
  campaign_expired: '¡La campaña ha caducado!',
  copy_clipboard: '¡Exp superpuesta copiada con éxito al portapapeles!',
  open_campaigns: 'Campañas Abiertas',
  campaign_name: 'Nombre de Campaña',
  campaign_level: 'Nivel<br />Completado',
  campaign_level_completed: 'Nivel Completado',
  campaign_score: 'Puntaje',
  campaign_details: 'Detalles',
  campaign_no_details: '&nbsp;',
  campaign_rewards: 'Cupones',
  campaign_no_rewards: '&nbsp;',
  campaign_start_date: 'Fecha de Inicio',
  campaign_end_date: 'Fecha Final',
  campaign_time_left: 'Tiempo Restante',
  campaign_includes: 'Incluir',
  current_score: 'Puntuación Actual',
  completed_level: 'Nivel Completado',
  next_level_score: 'Puntuación del Siguiente Nivel',
  browser_source_url: 'Barra EXP',
  browser_source_help: 'Haga clic en su versión preferida de la Barra EXP para copiarla y pegarla en su sistema OBS utilizando la URL proporcionada.',
  no_campaigns_available: 'No hay campañas disponibles',
  closed_campaigns: 'Campañas Cerradas',
  search_rewards: 'Cupones de Búsqueda',
  rewards_per_page: 'Cupones por Página',
  no_rewards_available: 'No hay cupones disponibles',
  tier: 'Nivel',
  coupon_code: 'Código Promocional',
  digital_reward_name: 'Nombre de Recompensa',
  digital_reward_mechanics: 'Mecánica de Recompensa',
  digital_mechanics: 'Mecánica de<br/>Recompensa',
  download_rewards: 'Descargar Cupones',
  welcome_to: 'Bienvenido a',
  sign_up: 'Registrarse',
  sign_up_incomplete: 'Registro incompleto',
  landing_title: '¡Regístrate aquí para participar en este desafío!',
  landing_subtitle: 'La siguiente información solo se utilizará para ésta campaña',
  landing_affiliate: 'Affiliate',
  landing_partner: 'Partner',
  landing_full_name: 'Nombre completo',
  landing_email: 'Correo electrónico',
  landing_contact: 'Teléfono de contacto',
  landing_country: 'País',
  landing_address: 'Dirección',
  landing_address_info: '*Esta dirección será utilizada para confirmar tu residencia en México y/o entregar premios en caso de haber recompensas físicas.',
  landing_state: 'Estado',
  landing_city: 'Ciudad',
  landing_marketing: 'Con propósitos de marketing, es posible que te incluyamos y formes parte de nuestros canales de comunicacion. ¿Estás de acuerdo y autorizas a Twitch para usar tu imagen, vídeos/clips o contenido de stream, con fines promocionales?',
  landing_attention: '*Atención: si violas las normas de la comunidad, Términos y condiciones de uso, la ley local, etc. Twitch tiene el derecho de eliminar cualquier recurso promocional.',
  landing_agree: 'Estoy de acuerdo y autorizo',
  landing_disagree: 'No estoy de acuerdo',
  landing_submit: 'Enviar',
  landing_success: '¡Registro completo! Estás dentro!',
  landing_click_here: 'Clica aquí',
  landing_success_info: 'para dirigirte a la web Glitch EXP para prepararte para una experiencia increíble con el desafío',
  landing_error: 'Lo sentimos, no cumples los requisitos necesarios :(',
  landing_error_info: 'La campaña de marketing es solo por invitación, tomamos nota de tu interés. Esperamos poder enviar una invitación para próximas campañas en un futuro.',
  unauthorized_message: 'Lo siento, actualmente no tienes acceso.',
  dashboard_unused_overlay: '¡Por favor, utiliza el overlay EXP en OBS!'
}
