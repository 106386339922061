<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>

<script>
export default {
  name: 'App',
  computed: {
    layout() {
      return (this.$route.meta.layout || 'default') + '-layout'
    }
  },
  created() {
    this.$vuetify.theme.dark = true
  }
}
</script>

<style lang="scss">
html {
  overflow-y: auto;
}

@font-face {
  font-family: RoobertRegular;
  src: url('/fonts/Roobert-Regular.otf') format('opentype');
}

.v-application {
  font-family: 'RoobertRegular' !important;
}

.v-application--wrap {
  background-color: black;

  @media only screen and (max-height: 900px) {
    width: 100% !important;
    height: 945px;
  }
}

.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
  background: transparent !important;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none !important;
  cursor: pointer !important;
}

.v-select__slot > input {
  cursor: pointer !important;
  color: black !important;
  font-size: 0px;
}

.v-input__icon--prepend-inner > i {
  color: black !important;
}

.v-btn__content {
  color: black !important;
  font-weight: bold;
  font-size: 18px;
  text-transform: capitalize;
  margin-left: 8px;
  margin-right: 8px;
  font-family: 'RoobertRegular' !important;
}

.v-chip__content {
  color: black !important;
  font-weight: bold;
}

.v-overlay__scrim {
  background-color: unset !important;
}

.v-overlay__content {
  z-index: 99999 !important;
}

.v-expansion-panel::before {
  box-shadow: unset !important;
}

.theme--dark.v-app-bar.v-toolbar.v-sheet {
  background-color: black !important;

  @media only screen and (max-height: 900px) {
    background: linear-gradient(180deg, rgba(163, 79, 229, 1) 0%, rgba(234, 112, 120, 1) 69.51576471328735%);
  }
}
</style>
