import { TSMInstance, TSMPublicInstance } from '@/api/instance'

const url = process.env.VUE_APP_BASE_API_URL

export async function timezones() {
  const response = await TSMInstance.get(`${url}api/Campaign/Timezones`)
  return response.data
}

export async function tags() {
  const response = await TSMInstance.get(`${url}api/Campaign/Tags`)
  return response.data
}

export async function openCampaigns() {
  const response = await TSMInstance.get(`${url}api/Campaign/OpenCampaigns`)
  return response.data
}

export async function closedCampaigns() {
  const response = await TSMInstance.get(`${url}api/Campaign/ClosedCampaigns`)
  return response.data
}

export async function createCampaign(campaign) {
  const response = await TSMInstance.post(`${url}api/Campaign`, campaign)
  return response.data
}

export async function deleteCampaign(campaignId) {
  const response = await TSMInstance.delete(`${url}api/Campaign/${campaignId}`)
  return response.data
}

export async function getCampaign(campaignId) {
  const response = await TSMInstance.get(`${url}api/Campaign/${campaignId}`)
  return response.data
}

export async function getWaitList(campaignId) {
  const response = await TSMInstance.get(`${url}api/Campaign/WaitList/${campaignId}`)
  return response.data
}

export async function getCampaignLanding(slug) {
  const response = await TSMPublicInstance.get(`${url}api/Campaign/Landing/${slug}`)
  return response.data
}

export async function getSignUpCheck(slug) {
  const response = await TSMInstance.get(`${url}api/Campaign/SignUpCheck/${slug}`)
  return response.data
}

export async function updateSignUp(campaignScore) {
  const response = await TSMInstance.post(`${url}api/Campaign/SignUp`, campaignScore)
  return response.data
}

export async function updateBonusScores(campaignId, scores) {
  const response = await TSMInstance.post(`${url}api/Campaign/Bonus/Scores/${campaignId}`, scores)
  return response.data
}

export async function updateBonusExp(campaignScoreId, score) {
  const response = await TSMInstance.put(`${url}api/Campaign/${campaignScoreId}/${score}`)
  return response.data
}

export async function blockScore(campaignScoreId, block) {
  const response = await TSMInstance.put(`${url}api/Campaign/${campaignScoreId}/Block/${block}`)
  return response.data
}

export async function approveAllEligible(campaignId) {
  const response = await TSMInstance.put(`${url}api/Campaign/${campaignId}/Eligible/Approve/All`)
  return response.data
}

export async function autoApproveCampaign(campaignId, enabled) {
  const response = await TSMInstance.put(`${url}api/Campaign/${campaignId}/Auto/Approve/${enabled}`)
  return response.data
}

export async function displayCouponsCampaign(campaignId, enabled) {
  const response = await TSMInstance.put(`${url}api/Campaign/${campaignId}/Display/Coupons/${enabled}`)
  return response.data
}

export async function myScores() {
  const response = await TSMInstance.get(`${url}api/Campaign/MyScores`)
  return response.data
}

export async function createScore(campaignId, channelId) {
  const response = await TSMInstance.post(`${url}api/Campaign/${campaignId}/${channelId}`, '')
  return response.data
}

export async function createScoreList(campaignId, channels) {
  const response = await TSMInstance.post(`${url}api/Campaign/${campaignId}`, channels)
  return response.data
}

export async function deleteScore(campaignId, channelId) {
  const response = await TSMInstance.delete(`${url}api/Campaign/${campaignId}/${channelId}`, '')
  return response.data
}

export async function loadOverlay(campaignId, channelId, slug) {
  const response = await TSMPublicInstance.get(`${url}api/Campaign/${campaignId}/${channelId}/${slug}`)
  return response.data
}

export async function setCampaignLogos(
  campaignId,
  overlayThemeLabel,
  overlayThemeLightLabel,
  overlayThemeImageUrl,
  overlayThemeLightImageUrl,
  overlayMessageText,
  overlayMessageInterval,
  overlayMessageDuration,
  overlayMessageStartDate,
  overlayMessageEndDate,
  progressBarImageUrl,
  progressBarLightImageUrl,
  logoImageUrl,
  logoLightImageUrl,
  sponsorImageUrl,
  sponsorLightImageUrl,
  sponsorImages,
  sponsorLightImages,
  sponsorText,
  sponsorLevel1ImageUrl,
  sponsorLevel1Name,
  sponsorLevel2ImageUrl,
  sponsorLevel2Name,
  sponsorLevel3ImageUrl,
  sponsorLevel3Name,
  sponsorLevel4ImageUrl,
  sponsorLevel4Name,
  sponsorLevel5ImageUrl,
  sponsorLevel5Name,
  sponsorLevel6ImageUrl,
  sponsorLevel6Name,
  sponsorLevel7ImageUrl,
  sponsorLevel7Name,
  sponsorLevel8ImageUrl,
  sponsorLevel8Name,
  sponsorLevel9ImageUrl,
  sponsorLevel9Name,
  sponsorLevel10ImageUrl,
  sponsorLevel10Name,
  sponsorLevel11ImageUrl,
  sponsorLevel11Name,
  sponsorLevel12ImageUrl,
  sponsorLevel12Name
) {
  const response = await TSMInstance.post(`${url}api/Campaign/Logos/${campaignId}`, {
    overlay_theme_label: overlayThemeLabel,
    overlay_theme_light_label: overlayThemeLightLabel,
    overlay_theme_image_url: overlayThemeImageUrl,
    overlay_theme_light_image_url: overlayThemeLightImageUrl,
    overlay_message_text: overlayMessageText,
    overlay_message_interval: overlayMessageInterval,
    overlay_message_duration: overlayMessageDuration,
    overlay_message_start_date: overlayMessageStartDate,
    overlay_message_end_date: overlayMessageEndDate,
    progress_bar_image_url: progressBarImageUrl,
    progress_bar_light_image_url: progressBarLightImageUrl,
    logo_image_url: logoImageUrl,
    logo_light_image_url: logoLightImageUrl,
    sponsor_image_url: sponsorImageUrl,
    sponsor_light_image_url: sponsorLightImageUrl,
    sponsor_images: sponsorImages,
    sponsor_light_images: sponsorLightImages,
    sponsor_text: sponsorText,
    sponsor_level1_image_url: sponsorLevel1ImageUrl,
    sponsor_level1_name: sponsorLevel1Name,
    sponsor_level2_image_url: sponsorLevel2ImageUrl,
    sponsor_level2_name: sponsorLevel2Name,
    sponsor_level3_image_url: sponsorLevel3ImageUrl,
    sponsor_level3_name: sponsorLevel3Name,
    sponsor_level4_image_url: sponsorLevel4ImageUrl,
    sponsor_level4_name: sponsorLevel4Name,
    sponsor_level5_image_url: sponsorLevel5ImageUrl,
    sponsor_level5_name: sponsorLevel5Name,
    sponsor_level6_image_url: sponsorLevel6ImageUrl,
    sponsor_level6_name: sponsorLevel6Name,
    sponsor_level7_image_url: sponsorLevel7ImageUrl,
    sponsor_level7_name: sponsorLevel7Name,
    sponsor_level8_image_url: sponsorLevel8ImageUrl,
    sponsor_level8_name: sponsorLevel8Name,
    sponsor_level9_image_url: sponsorLevel9ImageUrl,
    sponsor_level9_name: sponsorLevel9Name,
    sponsor_level10_image_url: sponsorLevel10ImageUrl,
    sponsor_level10_name: sponsorLevel10Name,
    sponsor_level11_image_url: sponsorLevel11ImageUrl,
    sponsor_level11_name: sponsorLevel11Name,
    sponsor_level12_image_url: sponsorLevel12ImageUrl,
    sponsor_level12_name: sponsorLevel12Name
  })
  return response.data
}

export async function setCampaign(campaignId, campaignName, localName, link1Name, link1Url, link2Name, link2Url, link3Name, link3Url) {
  const response = await TSMInstance.post(`${url}api/Campaign/Edit/${campaignId}`, {
    name: campaignName,
    local_name: localName,
    link1_name: link1Name,
    link1_url: link1Url,
    link2_name: link2Name,
    link2_url: link2Url,
    link3_name: link3Name,
    link3_url: link3Url
  })
  return response.data
}

export async function setCampaignLanding(
  campaignId,
  landingCustomSubtitle,
  landingDates,
  landingFaqsTitle,
  landingMultipleChoicesTitle,
  landingSignUpEnabled,
  landingBannerImageUrl,
  landingPrize1ImageUrl,
  landingPrize2ImageUrl,
  landingPrize3ImageUrl,
  landingPrize1Name,
  landingPrize2Name,
  landingPrize3Name,
  landingPrize4Name,
  landingPrize5Name,
  landingPrize6Name,
  landingPrize7Name,
  landingPrize8Name,
  landingPrize9Name,
  landingPrize10Name,
  landingPrize11Name,
  landingPrize12Name,
  landingCarouselInterval
) {
  const response = await TSMInstance.post(`${url}api/Campaign/Landing/${campaignId}`, {
    landing_custom_subtitle: landingCustomSubtitle,
    landing_dates: landingDates,
    landing_faqs_title: landingFaqsTitle,
    landing_multiple_choices_title: landingMultipleChoicesTitle,
    landing_sign_up_enabled: landingSignUpEnabled,
    landing_banner_image_url: landingBannerImageUrl,
    landing_prize1_image_url: landingPrize1ImageUrl,
    landing_prize2_image_url: landingPrize2ImageUrl,
    landing_prize3_image_url: landingPrize3ImageUrl,
    landing_prize1_name: landingPrize1Name,
    landing_prize2_name: landingPrize2Name,
    landing_prize3_name: landingPrize3Name,
    landing_prize4_name: landingPrize4Name,
    landing_prize5_name: landingPrize5Name,
    landing_prize6_name: landingPrize6Name,
    landing_prize7_name: landingPrize7Name,
    landing_prize8_name: landingPrize8Name,
    landing_prize9_name: landingPrize9Name,
    landing_prize10_name: landingPrize10Name,
    landing_prize11_name: landingPrize11Name,
    landing_prize12_name: landingPrize12Name,
    landing_carousel_interval: `${landingCarouselInterval}`
  })
  return response.data
}

export async function setCampaignFaqs(campaignId, faqs) {
  const response = await TSMInstance.post(`${url}api/Campaign/Faqs/${campaignId}`, {
    faqs
  })
  return response.data
}

export async function setCampaignMultipleChoices(campaignId, multipleChoices) {
  const response = await TSMInstance.post(`${url}api/Campaign/MultipleChoices/${campaignId}`, {
    multiple_choices: multipleChoices
  })
  return response.data
}

export async function setCampaignWhiteListUsers(campaignId, whiteListUsers) {
  const response = await TSMInstance.post(`${url}api/Campaign/WhiteListUsers/${campaignId}`, {
    landing_white_list_users: whiteListUsers
  })
  return response.data
}
