import Vue from 'vue'
import Vuex from 'vuex'
import random from '@/plugins/random'
import {
  refreshIdentity,
  updateIdentity,
  search,
  me,
  openCampaigns,
  closedCampaigns,
  createCampaign,
  myScores,
  createScore,
  createScoreList,
  deleteScore,
  getCampaign,
  getWaitList,
  getCampaignLanding,
  getStaffUsers,
  getSignUpCheck,
  updateSignUp,
  deleteCampaign,
  setCampaign,
  setCampaignLogos,
  setCampaignLanding,
  setCampaignFaqs,
  setCampaignMultipleChoices,
  setCampaignWhiteListUsers,
  setUserRole,
  setUserType,
  loadOverlay,
  timezones,
  tags,
  updateBonusExp,
  updateBonusScores,
  blockScore,
  blockUser,
  approveAllEligible,
  autoApproveCampaign,
  displayCouponsCampaign,
  createCampaignCouponList,
  getCampaignCouponList,
  getUserCampaignCouponList,
  deleteCoupon
} from '@/api'
import { oidcSettings } from '@/config/oidc'
import { isEmpty, orderBy } from 'lodash'

Vue.use(Vuex)

const allowedStaffUserIds = !isEmpty(process.env.VUE_APP_ALLOWED_STAFF_USER_IDS) ? process.env.VUE_APP_ALLOWED_STAFF_USER_IDS.trim().split(',') : []

export default new Vuex.Store({
  state: {
    user: null,
    staff: false,
    authUser: null,
    authTimeout: null,
    scores: null,
    openCampaigns: [],
    closedCampaigns: [],
    searchResults: [],
    overlay: null,
    timezones: [],
    tags: []
  },
  getters: {
    authUser: (state) => {
      if (state.authUser == null) {
        if (sessionStorage.user) {
          return JSON.parse(sessionStorage.user)
        }
        return null
      }
      return state.authUser
    },
    user: (state) => {
      return state.user
    },
    scores: (state) => {
      return state.scores
    },
    staff: (state) => {
      return state.staff
    },
    openCampaigns: (state) => {
      return state.openCampaigns
    },
    closedCampaigns: (state) => {
      return state.closedCampaigns
    },
    searchResults: (state) => {
      return state.searchResults
    },
    overlay: (state) => {
      return state.overlay
    },
    timezones: (state) => {
      return state.timezones
    },
    tags: (state) => {
      return state.tags
    }
  },
  mutations: {
    authUser(state, value) {
      if (value == null) {
        state.authUser = null
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('login')
        sessionStorage.removeItem('campaign')
      } else {
        state.authUser = value
        sessionStorage.user = JSON.stringify(value)
      }
    },
    user(state, user) {
      state.user = user
    },
    scores(state, scores) {
      state.scores = scores
    },
    staff(state, staff) {
      state.staff = staff
    },
    authTimeout(state, timeout) {
      state.authTimeout = timeout
    },
    openCampaigns(state, openCampaigns) {
      state.openCampaigns = openCampaigns
    },
    closedCampaigns(state, closedCampaigns) {
      state.closedCampaigns = closedCampaigns
    },
    campaign(state, campaign) {
      state.campaign = campaign
    },
    newCampaign(state, newCampaign) {
      state.openCampaigns.push(newCampaign)
    },
    searchResults(state, searchResults) {
      state.searchResults = searchResults
    },
    timezones(state, timezones) {
      state.timezones = timezones
    },
    tags(state, tags) {
      state.tags = tags
    },
    overlay(state, overlay) {
      state.overlay = overlay
    },
    newScore(state, newScore) {
      const campaigns = state.openCampaigns.filter((x) => newScore.campaign_id === x.id)
      campaigns.forEach((x) => {
        const channel = x.scores.filter((y) => y.id === newScore.id)
        if (channel.length === 0) {
          x.scores.push(newScore)
        }
      })
    },
    deleteScore(state, deleteScore) {
      const campaigns = state.openCampaigns.filter((x) => deleteScore.campaignId === x.id)
      campaigns.forEach((x) => {
        const newScores = []
        if (x.scores && x.scores.length > 0) {
          x.scores.forEach((y) => {
            if (y.channel.id !== deleteScore.scoreId) {
              newScores.push(y)
            }
          })
          x.scores = newScores
        }
      })
    },
    updateScore(state, updateScore) {
      let campaigns = state.openCampaigns.filter((x) => updateScore.campaign_id === x.id)
      campaigns = campaigns.concat(state.closedCampaigns.filter((x) => updateScore.campaign_id === x.id))
      ;(campaigns ?? []).forEach((x) => {
        ;(x.scores ?? []).forEach((y) => {
          if (y.id === updateScore.id) {
            y.score = updateScore.score
            y.bonus_e_x_p = updateScore.bonus_e_x_p
          }
        })
      })
    },
    deleteCampaign(state, deleteScore) {
      state.openCampaigns = state.openCampaigns.filter((x) => deleteScore.campaignId !== x.id)
      state.closedCampaigns = state.closedCampaigns.filter((x) => deleteScore.campaignId !== x.id)
    }
  },
  actions: {
    signin(context) {
      // generate a state value
      sessionStorage.state = random()
      // generate a nonce value
      sessionStorage.nonce = random()
      window.location = `${oidcSettings.authority}/authorize?client_id=${oidcSettings.client_id}&redirect_uri=${oidcSettings.redirect_uri}&response_type=code&scope=${oidcSettings.scope}&state=${sessionStorage.state}&nonce=${sessionStorage.nonce}`
    },
    signOut(context) {
      context.commit('user', null)
      return context.commit('authUser', null)
    },
    signinCallback(context, data) {
      return updateIdentity(data.code, data.nonce)
        .then((data) => {
          data.expires_at = Date.now() + (data.expires_in - 60) * 1000
          context.commit('authUser', data)
          sessionStorage.removeItem('nonce')
          sessionStorage.removeItem('state')
          return true
        })
        .catch((_) => {
          return false
        })
    },
    loadSystem(context) {
      if (sessionStorage.login === 'signUp') {
        return context.dispatch('getMe').then((_) => context.dispatch('autoRefreshToken', true))
      } else {
        return context
          .dispatch('getMe')
          .then((_) => context.dispatch('myScores'))
          .then((_) => context.dispatch('loadCampaigns'))
          .then((_) => context.dispatch('autoRefreshToken', true))
      }
    },
    loadOverlay(context, value) {
      return loadOverlay(value.campaign_id, value.channel_id, value.slug).then((result) => context.commit('overlay', result))
    },
    loadCampaigns(context) {
      if (context.state.staff) {
        const promises = []

        promises.push(openCampaigns().then((openCampaigns) => context.commit('openCampaigns', openCampaigns)))
        promises.push(closedCampaigns().then((closedCampaigns) => context.commit('closedCampaigns', closedCampaigns)))
        promises.push(timezones().then((timezones) => context.commit('timezones', timezones)))
        tags().then((tags) => context.commit('tags', tags))
        return Promise.all(promises).then((_) => true)
      } else {
        return true
      }
    },
    search(context, searchValue) {
      search(searchValue).then((searchResults) => {
        context.commit('searchResults', searchResults)
        return true
      })
    },
    myScores(context) {
      return myScores().then((scores) => {
        const scoresUnblocked = scores.filter((sc) => !sc.blocked)
        const sorted = orderBy(scoresUnblocked, ['campaign.start_date'], ['desc'])
        context.commit('scores', sorted)
        return true
      })
    },
    getMe(context) {
      return me().then((user) => {
        if (user.type === 'staff') {
          if (allowedStaffUserIds?.length > 0) {
            context.commit('staff', allowedStaffUserIds.includes(user.id))
          } else {
            context.commit('staff', true)
          }
        }
        context.commit('user', user)
        return true
      })
    },
    createCampaign(context, campaign) {
      if (context.state.staff) {
        createCampaign(campaign).then((newCamp) => {
          context.commit('newCampaign', newCamp)
          context.dispatch('loadCampaigns')
        })
      }
    },
    getCampaign(context, campaign) {
      if (context.state.staff) {
        return getCampaign(campaign).then((result) => {
          context.commit('campaign', result)
          return result
        })
      }
    },
    getWaitList(context, campaign) {
      if (context.state.staff) {
        return getWaitList(campaign).then((result) => {
          return result
        })
      }
    },
    getCampaignLanding(context, slug) {
      return getCampaignLanding(slug).then((result) => {
        return result
      })
    },
    getStaffUsers(context) {
      return getStaffUsers().then((result) => {
        return result
      })
    },
    getSignUpCheck(context, slug) {
      return getSignUpCheck(slug).then((result) => {
        return result
      })
    },
    submitSignUp(context, campaignScore) {
      return updateSignUp(campaignScore).then((result) => {
        return result
      })
    },
    addChannel(context, value) {
      createScore(value.campaignId, value.channelId).then((result) => {
        context.commit('newScore', result)
      })
    },
    addChannels(context, value) {
      return createScoreList(value.campaignId, value.channels).then((result) => {
        context.dispatch('loadCampaigns')
        return result
      })
    },
    deleteChannelScore(context, value) {
      return deleteScore(value.campaignId, value.scoreId).then((_) => {
        context.commit('deleteScore', value)
      })
    },
    deleteCampaign(context, value) {
      return deleteCampaign(value.campaignId).then((_) => {
        context.commit('deleteCampaign', value)
        context.dispatch('myScores')
      })
    },
    updateBonusExp(context, value) {
      updateBonusExp(value.scoreId, value.score).then((data) => {
        context.commit('updateScore', data)
      })
    },
    updateBonusScores(context, value) {
      return updateBonusScores(value.campaignId, value.scores).then((data) => {
        return data
      })
    },
    blockUser(context, value) {
      return blockUser(value.userId, value.block).then((data) => {
        return data
      })
    },
    blockScore(context, value) {
      return blockScore(value.scoreId, value.block).then((data) => {
        context.dispatch('myScores')
        return data
      })
    },
    approveAllEligible(context, campaign) {
      return approveAllEligible(campaign)
    },
    autoApproveCampaign(context, value) {
      return autoApproveCampaign(value.campaignId, value.enabled)
    },
    displayCouponsCampaign(context, value) {
      return displayCouponsCampaign(value.campaignId, value.enabled)
    },
    autoRefreshToken(context, checkTimeout) {
      if (context.getters.authUser !== null) {
        if (checkTimeout && context.state.authTimeout !== null) {
          return
        }
        const timeout = setTimeout(() => {
          if (context.getters.authUser !== null) {
            const user = context.getters.authUser
            refreshIdentity(user.refresh_token).then((data) => {
              user.expires_at = Date.now() + (user.expires_in - 60) * 1000
              user.access_token = data.access_token
              user.refresh_token = data.refresh_token
              context.commit('authUser', user)
              context.dispatch('autoRefreshToken', false)
            })
          }
        }, 60 * 60 * 1000)
        context.commit('authTimeout', timeout)
      }
    },
    createCampaignCoupons(context, value) {
      if (context.state.staff) {
        return createCampaignCouponList(value.campaignId, value.fileName, value.content)
      }
    },
    getCampaignCoupons(context, campaign) {
      if (context.state.staff) {
        return getCampaignCouponList(campaign)
      }
    },
    getUserCampaignCoupons(context, campaign) {
      if (context.state.user) {
        return getUserCampaignCouponList(campaign)
      }
    },
    deleteCoupon(context, coupon) {
      if (context.state.staff) {
        return deleteCoupon(coupon)
      }
    },
    updateCampaignLogos(context, value) {
      if (context.state.staff) {
        return setCampaignLogos(
          value.campaignId,
          value.overlayThemeLabel,
          value.overlayThemeLightLabel,
          value.overlayThemeImageUrl,
          value.overlayThemeLightImageUrl,
          value.overlayMessageText,
          value.overlayMessageInterval,
          value.overlayMessageDuration,
          value.overlayMessageStartDate,
          value.overlayMessageEndDate,
          value.progressBarImageUrl,
          value.progressBarLightImageUrl,
          value.logoImageUrl,
          value.logoLightImageUrl,
          value.sponsorImageUrl,
          value.sponsorLightImageUrl,
          value.sponsorImages,
          value.sponsorLightImages,
          value.sponsorText,
          value.sponsorLevel1ImageUrl,
          value.sponsorLevel1Name,
          value.sponsorLevel2ImageUrl,
          value.sponsorLevel2Name,
          value.sponsorLevel3ImageUrl,
          value.sponsorLevel3Name,
          value.sponsorLevel4ImageUrl,
          value.sponsorLevel4Name,
          value.sponsorLevel5ImageUrl,
          value.sponsorLevel5Name,
          value.sponsorLevel6ImageUrl,
          value.sponsorLevel6Name,
          value.sponsorLevel7ImageUrl,
          value.sponsorLevel7Name,
          value.sponsorLevel8ImageUrl,
          value.sponsorLevel8Name,
          value.sponsorLevel9ImageUrl,
          value.sponsorLevel9Name,
          value.sponsorLevel10ImageUrl,
          value.sponsorLevel10Name,
          value.sponsorLevel11ImageUrl,
          value.sponsorLevel11Name,
          value.sponsorLevel12ImageUrl,
          value.sponsorLevel12Name
        ).then((_) => context.dispatch('loadCampaigns'))
      }
    },
    updateCampaignEdit(context, value) {
      if (context.state.staff) {
        return setCampaign(value.campaignId, value.name, value.localName, value.link1Name, value.link1Url, value.link2Name, value.link2Url, value.link3Name, value.link3Url).then((_) => context.dispatch('loadCampaigns'))
      }
    },
    updateCampaignLanding(context, value) {
      if (context.state.staff) {
        return setCampaignLanding(
          value.campaignId,
          value.landingCustomSubtitle,
          value.landingDates,
          value.landingFaqsTitle,
          value.landingMultipleChoicesTitle,
          value.landingSignUpEnabled,
          value.landingBannerImageUrl,
          value.landingPrize1ImageUrl,
          value.landingPrize2ImageUrl,
          value.landingPrize3ImageUrl,
          value.landingPrize1Name,
          value.landingPrize2Name,
          value.landingPrize3Name,
          value.landingPrize4Name,
          value.landingPrize5Name,
          value.landingPrize6Name,
          value.landingPrize7Name,
          value.landingPrize8Name,
          value.landingPrize9Name,
          value.landingPrize10Name,
          value.landingPrize11Name,
          value.landingPrize12Name,
          value.landingCarouselInterval
        ).then((_) => context.dispatch('loadCampaigns'))
      }
    },
    updateCampaignFaqs(context, value) {
      if (context.state.staff) {
        return setCampaignFaqs(value.campaignId, value.faqs).then((_) => context.dispatch('loadCampaigns'))
      }
    },
    updateCampaignMultipleChoices(context, value) {
      if (context.state.staff) {
        return setCampaignMultipleChoices(value.campaignId, value.multipleChoices).then((_) => context.dispatch('loadCampaigns'))
      }
    },
    updateCampaignWhiteListUsers(context, value) {
      if (context.state.staff) {
        return setCampaignWhiteListUsers(value.campaignId, value.whiteListUsers).then((_) => context.dispatch('loadCampaigns'))
      }
    },
    updateUserRole(context, value) {
      if (context.state.staff) {
        return setUserRole(value.userId, value.role)
      }
    },
    updateUserType(context, value) {
      if (context.state.staff) {
        return setUserType(value.userId, value.type)
      }
    }
  },
  modules: {}
})
