import { ja } from 'vuetify/lib/locale'

export default {
  ...ja,
  title: 'Glitch Exp',
  login: 'ログイン',
  logout: 'ログアウト',
  heading_welcome: 'EXPへようこそ',
  home_para1: 'GLITCH EXP は、Twitch Interactive 向けに開発されたツールで、以下のキャンペーンで使用します。',
  rewards: 'クリエイターに報酬を与える',
  home_para1_1: ' 獲得したポイントに基づいて。ポイントを獲得すればするほど、より大きな報酬を受け取れます。ポイントの獲得方法はキャンペーンごとに異なり、Twitchが指定する条件によっても異なります。',
  home_para1_2: 'を読んでください。',
  home_para1_3: ' ご質問がございましたらお問い合わせください。<br/><br/>',
  reminder: 'リマインダー',
  home_para2: 'キャンペーンへの参加登録をすでに完了しており、関連するフォームにあなたのTwitchユーザー名を入力していることを必ずご確認ください。ウェブサイトを開いた際には、あなたが参加しているキャンペーンのみが表示されます。<br/><br/><br/>',
  terms: '利用規約',
  privacy_policy: 'プライバシーポリシー',
  develop_by: 'このツールの開発は、X-Team およびその他の開発パートナーと共同で開発されました。',
  level: 'レベル',
  completed: 'おめでとう!',
  congratulations: 'おめでとう!',
  challenge_completed: 'チャレンジが完了しました!',
  campaign_expired: 'キャンペーンの有効期限が切れています！',
  copy_clipboard: 'Exp OverlayはClipboardに正常にコピーされました！',
  open_campaigns: 'オープンキャンペーン',
  campaign_name: 'キャンペーン名',
  campaign_level: '完了したレベル',
  campaign_level_completed: '完了したレベル',
  campaign_score: 'スコア',
  campaign_details: '詳細',
  campaign_no_details: '&nbsp;',
  campaign_rewards: '報酬',
  campaign_no_rewards: '&nbsp;',
  campaign_start_date: '開始日',
  campaign_end_date: '終了日',
  campaign_time_left: '残り時間',
  campaign_includes: '含まれる',
  current_score: '現在のスコア',
  completed_level: '完了したレベル',
  next_level_score: '次のレベルまでのスコア',
  browser_source_url: 'ブラウザソースURL',
  browser_source_help: '希望のブラウザソースのURLをクリックしてコピーをし、お使いのＯＢＳに貼り付けて使用します。',
  no_campaigns_available: '利用可能なキャンペーンはありません',
  closed_campaigns: 'クローズドキャンペーン',
  search_rewards: '報酬を検索',
  rewards_per_page: '参加者ごとの報酬',
  no_rewards_available: '利用可能な報酬はありません',
  tier: 'ティア',
  coupon_code: 'クーポンコード',
  digital_reward_name: 'デジタル報酬名',
  digital_reward_mechanics: 'デジタル報酬メカニック',
  digital_mechanics: 'デジタル報酬メカニック',
  download_rewards: 'ダウンロード特典',
  welcome_to: 'へようこそ',
  sign_up: '登録する',
  sign_up_incomplete: 'サインアップが未完了',
  landing_title: 'こちらから登録しましょう！',
  landing_subtitle: '以下の情報は本キャンペーンのためにのみ使用されます。',
  landing_affiliate: 'アフィリエイト',
  landing_partner: 'パートナー',
  landing_full_name: '氏名',
  landing_email: 'eメール',
  landing_contact: 'Discord ID',
  landing_country: '国名',
  landing_address: '住所',
  landing_address_info: '*直近で引っ越し予定のある方は別途実行委員にご連絡ください。',
  landing_state: '都道府県',
  landing_city: '市区町村',
  landing_marketing: 'マーケティングを目的として、当社のコミュニケーションチャンネルであなたを紹介する場合があります。Twitchがあなたの肖像、ビデオ/クリップ、ストリームコンテンツを広告宣伝に使用することに同意しますか？',
  landing_attention: '*注意：コミュニティガイドライン、T&S、地域の法律などに違反した場合、Twitchはプロモーションリソースを削除する権利を有します。',
  landing_agree: '同意する',
  landing_disagree: '同意しない',
  landing_submit: '登録する',
  landing_success: '登録が無事終わりました！',
  landing_click_here: 'Glitch EXP',
  landing_success_info: 'のウェブサイトにアクセスして の準備をしましょう',
  landing_error: '残念ながら、あなたは今回のキャンペーンに参加できません。',
  landing_error_info: '今回は審査の結果、ご参加を見送らせていただきましたが、近い将来、次回のキャンペーンにご招待させていただきたいと思いますので、またのご応募お待ちしております。',
  unauthorized_message: '申し訳ありませんが、現在アクセスできません。',
  dashboard_unused_overlay: 'OBSでEXPオーバーレイをご利用ください！'
}
