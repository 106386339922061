import { de } from 'vuetify/lib/locale'

export default {
  ...de,
  title: 'Glitch Exp',
  login: 'Anmeldung',
  logout: 'Ausloggen',
  heading_welcome: 'Willkommen bei EXP',
  home_para1: 'GLITCH EXP ist ein für Twitch Interactive entwickeltes Tool. Dies wird für Kampagnen verwendet, in denen ',
  rewards: 'Creators',
  home_para1_1: ' für ihre gesammelten Punkte belohnt werden. Je mehr Punkte du sammelst, desto größer ist die Belohnung. Die Art und Weise, wie du Punkte sammelst, hängt von der jeweiligen Kampagne und den von Twitch festgelegten Kriterien ab.',
  home_para1_2: 'Bitte lese die ',
  home_para1_3: ' durch und lass es uns gerne wissen, wenn du Fragen hast.',
  reminder: 'Als Erinnerung',
  home_para2:
    'Bitte stelle sicher, dass du dich bereits für die Teilnahme an einer Kampagne angemeldet und deinen Twitch-Benutzernamen im entsprechenden Formular angegeben hast. Wenn du dich noch nicht auf unsere Webseite angemeldet hast, bevor du diese function benutzt, wirst du keine Prämien verdienen können.',
  terms: 'Nutzungsbedingungen',
  privacy_policy: 'Datenschutzbestimmungen',
  develop_by: 'Dieses Tool wurde in Zusammenarbeit mit X-Team und anderen Entwicklungspartnern entwickelt.',
  level: 'Level',
  completed: 'Vollendet!',
  congratulations: '',
  challenge_completed: 'Herausforderung Abgeschlossen!',
  campaign_expired: 'Die Kampagne ist abgelaufen!',
  copy_clipboard: 'EXP Overlay wurde erfolgreich in die Zwischenablage kopiert!',
  open_campaigns: 'Offene Kampagnen',
  campaign_name: 'Kampagnenname',
  campaign_level: 'Level<br />Abgeschlossen',
  campaign_level_completed: 'Level Abgeschlossen',
  campaign_score: 'Punktzahl',
  campaign_details: 'Einzelheiten',
  campaign_no_details: '&nbsp;',
  campaign_rewards: 'Prämien',
  campaign_no_rewards: '&nbsp;',
  campaign_start_date: 'Startdatum',
  campaign_end_date: 'Endtermin',
  campaign_time_left: 'Übrige Zeit',
  campaign_includes: 'Inklusive',
  current_score: 'Momentaner Punktestand',
  completed_level: 'Abgeschlossenes Level',
  next_level_score: 'Nächste Level -Score',
  browser_source_url: 'Browserquelle URL',
  browser_source_help: 'Bitte klicke auf deine bevorzugte Version der URL-Browserquelle, um die bereitgestellte URL in dein OBS einzufügen.',
  no_campaigns_available: 'Keine kampagnen verfügbar',
  closed_campaigns: 'Geschlossene Kampagnen',
  search_rewards: 'Suche Prämien',
  rewards_per_page: 'Prämien pro Teilnehmer',
  no_rewards_available: 'Keine belohnungen verfügbar',
  tier: 'Ebene',
  coupon_code: 'Gutscheincode',
  digital_reward_name: 'Digitaler Belohnungsname',
  digital_reward_mechanics: 'Digitale Belohnungsmechanik',
  digital_mechanics: 'Digitale Belohnungs<br/>mechanik',
  download_rewards: 'Prämien Herunterladen',
  welcome_to: 'Willkommen bei',
  sign_up: 'Anmelden',
  sign_up_incomplete: 'Anmeldung unvollständig',
  landing_title: 'Melde dich hier an, um Teil der Challenge zu sein!',
  landing_subtitle: 'Die folgenden Infos werden nur für diese Kampagne genutzt.',
  landing_affiliate: 'Affiliate',
  landing_partner: 'Partner',
  landing_full_name: 'Vollständiger Name',
  landing_email: 'E-Mail',
  landing_contact: 'Telefonnummer',
  landing_country: 'Land',
  landing_address: 'Adresse',
  landing_address_info: '*Dies dient dazu, dass du physische Waren erhältst. Falls du in den nächsten drei Monaten umziehen wirst, trage bitte deine aktuelle Versandadresse ein und markiere hier *Umzug geplant*.',
  landing_state: 'Bundesland',
  landing_city: 'Stadt',
  landing_marketing: 'Zu Marketingzwecken könnten wir dich auf unseren Kommunikationskanälen präsentieren. Stimmst du zu, dass Twitch deine Abbildung, Videos/Clips oder Stream-Inhalte für Werbezwecke verwenden darf?',
  landing_attention: '*Hinweis: Wenn du gegen die Community-Richtlinien, die Nutzungsbedingungen oder lokale Gesetze usw. verstößt, behält sich Twitch das Recht vor, jegliche Werbemittel zu entfernen.',
  landing_agree: 'Ich stimme zu',
  landing_disagree: 'Ich stimme nicht zu',
  landing_submit: 'Abschicken',
  landing_success: 'Anmeldung erfolgreich! Du bist dabei!',
  landing_click_here: 'Klicke hier',
  landing_success_info: ', um zur Glitch EXP-Website zu gelangen und dich auf ein aufregendes Erlebnis mit dem vorzubereiten',
  landing_error: 'Es tut uns leid, du hast dich leider nicht qualifiziert.',
  landing_error_info: 'Die Marketingkampagne ist nur auf Einladung und wir haben dein Interesse zur Kenntnis genommen. Wir hoffen, dir in naher Zukunft eine Einladung für unsere kommenden Kampagnen zukommen lassen zu können.',
  unauthorized_message: 'Entschuldigung, Sie haben derzeit keinen Zugriff.',
  dashboard_unused_overlay: 'Bitte verwenden Sie das EXP-Overlay in OBS!'
}
