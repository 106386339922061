import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: {
      layout: 'login'
    }
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Unauthorized.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/oidc-callback',
    name: 'oidcCallback',
    component: () => import(/* webpackChunkName: "oidcCallback" */ '@/views/OidcCallback.vue'),
    meta: {
      layout: 'login'
    }
  },
  {
    path: '/campaign/:slug',
    name: 'campaign',
    component: () => import(/* webpackChunkName: "campaign" */ '@/views/Campaign.vue'),
    meta: {
      layout: 'login'
    }
  },
  {
    path: '/sign-up',
    name: 'signUp',
    component: () => import(/* webpackChunkName: "signUp" */ '@/views/SignUp.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/channels/:campaignId',
    name: 'channels',
    component: () => import(/* webpackChunkName: "channels" */ '@/views/Channels.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/coupons/:campaignId',
    name: 'coupons',
    component: () => import(/* webpackChunkName: "coupons" */ '@/views/Coupons.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/rewards/:campaignId',
    name: 'rewards',
    component: () => import(/* webpackChunkName: "rewards" */ '@/views/Rewards.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/faqs/:campaignId',
    name: 'faqs',
    component: () => import(/* webpackChunkName: "faqs" */ '@/views/Faqs.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/multiple-choices/:campaignId',
    name: 'multipleChoices',
    component: () => import(/* webpackChunkName: "multipleChoices" */ '@/views/MultipleChoices.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/user-mgmt',
    name: 'userMgmt',
    component: () => import(/* webpackChunkName: "userMgmt" */ '@/views/UserMgmt.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/white-list/:campaignId',
    name: 'whiteList',
    component: () => import(/* webpackChunkName: "whiteList" */ '@/views/WhiteList.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/wait-list/:campaignId',
    name: 'waitList',
    component: () => import(/* webpackChunkName: "waitList" */ '@/views/WaitList.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/overlay/:slug/:channelId/:campaignId/:theme',
    name: 'overlay',
    component: () => import(/* webpackChunkName: "overlay" */ '@/views/Overlay.vue'),
    meta: {
      layout: 'blank'
    }
  }
]

const router = new Router({
  mode: 'history',
  base: './',
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (requiresAuth) {
    if (store.getters.authUser != null) {
      next()
    } else {
      router.push({ name: 'home' })
    }
  } else {
    next()
  }
})

export default router
