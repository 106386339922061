import { en } from 'vuetify/lib/locale'

export default {
  ...en,
  title: 'Glitch Exp',
  login: 'Login',
  logout: 'Logout',
  heading_welcome: 'Welcome to EXP',
  home_para1: 'GLITCH EXP is a tool developed for Twitch Interactive for use in campaigns that ',
  rewards: 'rewards creators',
  home_para1_1: ' based on the points they earn. The more points you earn the bigger the reward you receive. The way you earn points will vary based on each campaign and the criteria determined by Twitch.',
  home_para1_2: 'Please read the ',
  home_para1_3: ' and contact us if you have any questions.<br/><br/>',
  reminder: 'Reminder',
  home_para2: 'Please ensure you have already signed up to participate in a campaign and provided your Twitch username on the relevant form. When you enter the website, you will only see the campaign you are participating in.<br/><br/><br/><br/>',
  terms: 'Terms of Service',
  privacy_policy: 'Privacy Policy',
  develop_by: 'This tool was developed in conjunction with X-Team and other development partners.',
  level: 'Level',
  completed: 'Completed!',
  congratulations: 'Congrats!',
  challenge_completed: 'Challenge Completed!',
  campaign_expired: 'The campaign is expired!',
  copy_clipboard: 'EXP Overlay URL copied to clipboard!',
  open_campaigns: 'Open Campaigns',
  campaign_name: 'Campaign Name',
  campaign_level: 'Level<br />Completed',
  campaign_level_completed: 'Level Completed',
  campaign_score: 'Score',
  campaign_details: 'Details',
  campaign_no_details: '&nbsp;',
  campaign_rewards: 'Rewards',
  campaign_no_rewards: '&nbsp;',
  campaign_start_date: 'Start Date',
  campaign_end_date: 'End Date',
  campaign_time_left: 'Time Left',
  campaign_includes: 'Includes',
  current_score: 'Current Score',
  completed_level: 'Completed Level',
  next_level_score: 'Next Level Score',
  browser_source_url: 'Browser Source URL',
  browser_source_help: 'Please click your preferred version of the Browser Source URL to copy it and paste it into your OBS system using the provided URL.',
  no_campaigns_available: 'No campaigns available',
  closed_campaigns: 'Closed Campaigns',
  search_rewards: 'Search Rewards',
  rewards_per_page: 'Rewards per Page',
  no_rewards_available: 'No rewards available',
  tier: 'Tier',
  coupon_code: 'Coupon Code',
  digital_reward_name: 'Digital Reward Name',
  digital_reward_mechanics: 'Digital Reward Mechanics',
  digital_mechanics: 'Digital Reward <br/>Mechanics',
  download_rewards: 'Download Rewards',
  starting_soon: 'Starting soon',
  welcome_to: 'Welcome to',
  sign_up: 'Sign up',
  sign_up_incomplete: 'Sign Up Incomplete',
  landing_title: 'Register to join the fun!',
  landing_subtitle: 'The following information will only be used for this campaign.',
  landing_affiliate: 'Affiliate',
  landing_partner: 'Partner',
  landing_full_name: 'Full name',
  landing_email: 'Email address',
  landing_contact: 'Contact number',
  landing_country: 'Country',
  landing_address: 'Address',
  landing_address_info: '*This is for you to receive physical goods. if you are going to move house in the following three months, please put your current shipping address and mark *plan to move house* here.',
  landing_state: 'State',
  landing_city: 'City',
  landing_marketing: 'For marketing purposes, we might feature you in our communication channels. Do you agree to authorize Twitch to use your likeness, videos/clips or stream contents on advertising promotion?',
  landing_attention: '*Attention: If you violate the community guideline, T&S or local law etc., Twitch has right to remove any promotional resources.',
  landing_agree: 'I agree',
  landing_disagree: 'I disagree',
  landing_submit: 'Register',
  landing_success: "Success! You're confirmed!",
  landing_click_here: 'Click here',
  landing_success_info: 'to head to the Glitch EXP website and prepare for the upcoming',
  landing_error: "Sorry, you're not eligible :(",
  landing_error_info: "The program is invitation-only at the moment but we've taken note of your interest. We hope to extend an invitation for our upcoming campaigns in the near future.",
  unauthorized_message: "Sorry, you currently don't have access.",
  dashboard_unused_overlay: 'Please Use EXP overlay in OBS!'
}
