import 'es6-promise/auto'

import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import VueClipboard from 'vue-clipboard2'
import VueVirtualScroller from 'vue-virtual-scroller'
import VuePapaParse from 'vue-papa-parse'
import VTiptap from '@peepi/vuetify-tiptap'

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import '@splidejs/splide/dist/css/splide.min.css'

import Default from '@/layouts/Default'
import Login from '@/layouts/Login'
import Blank from '@/layouts/Blank'

import { setTSMInterceptor } from '@/api/instance'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitch, faDiscord, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faUserShield, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faTwitch)
library.add(faDiscord)
library.add(faTwitter)
library.add(faLinkedin)
library.add(faUserShield)
library.add(faChevronLeft)

// used to remove the loading state and display it accordingly
function stopTsmLoading(data) {
  store.dispatch('setTsmLoading', false)
  return data
}

setTSMInterceptor(
  (config) => {
    return {
      ...config,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        Authorization: `Bearer ${store.getters.authUser.id_token}`
      }
    }
  },
  (data) => data,
  stopTsmLoading,
  stopTsmLoading
)

Vue.config.productionTip = false

Vue.use(VueClipboard)
Vue.use(VueVirtualScroller)
Vue.use(VuePapaParse)
Vue.use(VTiptap)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('default-layout', Default)
Vue.component('login-layout', Login)
Vue.component('blank-layout', Blank)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
