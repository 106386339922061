import { TSMInstance } from '@/api/instance'

const url = process.env.VUE_APP_BASE_API_URL

export async function search(query) {
  const response = await TSMInstance.get(`${url}api/User/${query}`)
  return response.data
}

export async function me() {
  const response = await TSMInstance.get(`${url}api/User`)
  return response.data
}

export async function blockUser(userId, block) {
  const response = await TSMInstance.put(`${url}api/User/${userId}/Block/${block}`)
  return response.data
}

export async function getStaffUsers() {
  const response = await TSMInstance.get(`${url}api/User/StaffUsers`)
  return response.data
}

export async function setUserRole(userId, role) {
  const response = await TSMInstance.put(`${url}api/User/${userId}/Role/${role}`)
  return response.data
}

export async function setUserType(userId, type) {
  const response = await TSMInstance.put(`${url}api/User/${userId}/Type/${type}`)
  return response.data
}
