import { TSMInstance } from '@/api/instance'

const url = process.env.VUE_APP_BASE_API_URL

export async function createCampaignCouponList(campaignId, fileName, content) {
  const response = await TSMInstance.post(`${url}api/Coupon/Campaign/${campaignId}`, {
    file_name: fileName,
    rows: content.data
  })
  return response.data
}

export async function getCampaignCouponList(campaignId) {
  const response = await TSMInstance.get(`${url}api/Coupon/Campaign/${campaignId}`)
  return response.data
}

export async function getUserCampaignCouponList(campaignId) {
  const response = await TSMInstance.get(`${url}api/Coupon/Campaign/${campaignId}/MyCoupons`)
  return response.data
}

export async function deleteCoupon(couponId) {
  const response = await TSMInstance.delete(`${url}api/Coupon/${couponId}`)
  return response.data
}
