import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { de, en, es, fr, ja, pt, zh } from '@/locale'

Vue.use(Vuetify)

export default new Vuetify({
  buttons: { rounded: true },
  lang: {
    locales: { de, en, es, fr, ja, pt, zh },
    current: 'en'
  },
  theme: {
    themes: {
      dark: {
        primary: '#beff00'
      }
    }
  }
})
