import { fr } from 'vuetify/lib/locale'

export default {
  ...fr,
  title: 'Glitch Exp',
  login: 'Connexion',
  logout: 'Se déconnecter',
  heading_welcome: 'Bienvenue sur EXP',
  home_para1: 'Glitch Exp est un outil développé pour Twitch Interactive dans le cadre de campagnes qui ',
  rewards: 'récompensent les créateurs',
  home_para1_1: " en fonction des points qu'ils gagnent. Plus vous gagnez de points, plus grande est la récompense que vous recevez. La façon dont vous gagnez des points variera en fonction de chaque campagne et des critères déterminés en amont par Twitch.",
  home_para1_2: 'Veuillez lire la ',
  home_para1_3: ' et nous contacter si vous avez des questions.<br/>',
  reminder: 'Rappel',
  home_para2: "Veuillez vous assurer que vous êtes déjà inscrit pour participer à une campagne et ayez fourni votre nom d'utilisateur Twitch sur le formulaire prévu à cet effet. Lorsque vous entrez sur le site Web, vous verrez uniquement la campagne à laquelle vous participez.<br/><br/><br/>",
  terms: "Conditions d'utilisation",
  privacy_policy: 'Politique de confidentialité',
  develop_by: "Cet outil a été développé conjointement avec X-Team et d'autres partenaires de développement.",
  level: 'Niveau',
  completed: 'Bravo!',
  congratulations: 'Bravo!',
  challenge_completed: 'Défi Relevé!',
  campaign_expired: 'La campagne est expirée!',
  copy_clipboard: 'EXP superposition a copié avec succès dans le presse-papiers!',
  open_campaigns: 'Campagnes Ouvertes',
  campaign_name: 'Nom de la Campagne',
  campaign_level: 'Niveau<br />Terminé',
  campaign_level_completed: 'Niveau Terminé',
  campaign_score: 'Score',
  campaign_details: 'Détails',
  campaign_no_details: '&nbsp;',
  campaign_rewards: 'Récompenses',
  campaign_no_rewards: '&nbsp;',
  campaign_start_date: 'Date de Début',
  campaign_end_date: 'Date de Fin',
  campaign_time_left: 'Temps Restant',
  campaign_includes: 'Comprend',
  current_score: 'Score Actuel',
  completed_level: 'Niveau Terminé',
  next_level_score: 'Score de Niveau Suivant',
  browser_source_url: 'URL de Source du Navigateur',
  browser_source_help: "Veuillez cliquer sur votre version préférée de l'URL de la source du navigateur pour la copier et collez-la dans votre système OBS à l'aide de l'URL fournie.",
  no_campaigns_available: 'Aucune campagne disponible',
  closed_campaigns: 'Campagnes Fermées',
  search_rewards: 'Rechercher des Récompenses',
  rewards_per_page: 'Récompenses par Page',
  no_rewards_available: 'Aucune récompense disponible',
  tier: 'Niveau',
  coupon_code: 'Code Promo',
  digital_reward_name: 'Nom de Récompense',
  digital_reward_mechanics: 'Mécanique de Récompense',
  digital_mechanics: 'Mécanique de<br/>Récompense',
  download_rewards: 'Télécharger Récompenses',
  welcome_to: 'Bienvenue sur',
  sign_up: "S'inscrire",
  sign_up_incomplete: 'Inscription incomplète',
  landing_title: 'Inscrivez-vous ici pour rejoindre le challenge!',
  landing_subtitle: 'Les informations suivantes seront utilisées uniquement dans le cadre de cette campagne.',
  landing_affiliate: 'Affilié',
  landing_partner: 'Partenaire',
  landing_full_name: 'Nom complet',
  landing_email: 'E-mail',
  landing_contact: 'Numéro de téléphone',
  landing_country: 'Pays',
  landing_address: 'Adresse',
  landing_address_info: '*Nous avons besoin de cette information pour vous envoyer vos récompenses. Si vous prévoyez de déménager dans les trois prochains mois, veuillez indiquer votre adresse de livraison actuelle et mentionner *déménagement à venir* ici.',
  landing_state: 'Région',
  landing_city: 'Ville',
  landing_marketing: 'Pour des raisons de marketing, nous pourrions vous présenter sur nos canaux de communication. Autorisez-vous Twitch à utiliser votre apparence, vos vidéos/clips ou le contenu de votre stream à des fins de promotion publicitaire?',
  landing_attention: '**Attention : si vous enfreignez les règles de la communauté, les conditions générales de service, ou les lois locales, Twitch se réserve le droit de retirer toute ressource promotionnelle.',
  landing_agree: "J'accepte",
  landing_disagree: 'Je refuse',
  landing_submit: 'Soumettre',
  landing_success: 'Inscription réussie! Bienvenue!',
  landing_click_here: 'Cliquez ici',
  landing_success_info: 'pour accéder à Glitch EXP et préparez-vous à vivre une expérience folle avec le challenge',
  landing_error: "Désolé, vous n'êtes pas admissible :(",
  landing_error_info: 'Cette campagne marketing est uniquement sur invitation, et nous avons pris note de votre intérêt. Nous espérons pouvoir vous inviter à nos prochaines campagnes dans un avenir proche.',
  unauthorized_message: "Désolé, vous n'avez actuellement pas accès.",
  dashboard_unused_overlay: 'Veuillez utiliser la superposition EXP dans OBS!'
}
