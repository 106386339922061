import { zhHant } from 'vuetify/lib/locale'

export default {
  ...zhHant,
  title: 'Glitch Exp',
  login: '登入',
  logout: '登出',
  heading_welcome: '歡迎來到EXP',
  home_para1: 'GLITCH EXP 是為 Twitch Interactive 開發的一種工具，用於活動',
  rewards: '獎勵創作者',
  home_para1_1: ' 根據他們賺取的積分。贏得的積分越多，獲得的獎勵也越大。贏得積分的方式會根據Twitch設計的各個行銷活動條件而不同。',
  home_para1_2: '請閱讀 ',
  home_para1_3: ' 如果您有任何問題，請聯繫我們。<br/><br/><br/><br/><br/>',
  reminder: '提醒',
  home_para2: '請確認您完成註冊行銷活動才能參加，並在相關表單提供您的 Twitch 使用者名稱。進入網站後，您只會看到您參加的行銷活動。<br/><br/><br/><br/><br/><br/>',
  terms: '服務條款',
  privacy_policy: '隱私權政策',
  develop_by: '這項工具是與 X-Team 和其他開發合作夥伴共同開發',
  level: '級',
  completed: '挑戰完成！',
  congratulations: '恭喜!',
  challenge_completed: '挑戰完成！',
  campaign_expired: '該活動已過期！',
  copy_clipboard: 'EXP小工具顯示版本，複製成功！',
  open_campaigns: '進行中的活動',
  campaign_name: '活動名稱',
  campaign_level: '達標層級',
  campaign_level_completed: '達標層級',
  campaign_score: '分數',
  campaign_details: '細節',
  campaign_no_details: '&nbsp;',
  campaign_rewards: '獎勵',
  campaign_no_rewards: '&nbsp;',
  campaign_start_date: '開始日期',
  campaign_end_date: '結束日期',
  campaign_time_left: '剩餘時間',
  campaign_includes: '包括',
  current_score: '當前分數',
  completed_level: '達標層級',
  next_level_score: '下個層級目標分數',
  browser_source_url: 'Browser Source URL',
  browser_source_help: '請點擊複製您首選的EXP小工具顯示版本，並使用提供的Browser Source URL，設定至您的OBS系統中。',
  no_campaigns_available: '沒有可用的廣告活動',
  closed_campaigns: '結束的活動',
  search_rewards: '搜尋獎勵',
  rewards_per_page: '每位參與者的獎勵',
  no_rewards_available: '沒有可用獎勵',
  tier: '層級',
  coupon_code: '優惠券代碼',
  digital_reward_name: '數位類型獎勵名稱',
  digital_reward_mechanics: '數位類型獎勵機制',
  digital_mechanics: '數位類型獎勵機制',
  download_rewards: '下載獎勵',
  welcome_to: '歡迎來到',
  sign_up: '報名',
  sign_up_incomplete: '報名未完成',
  landing_title: '報名成為本活動的一份子！',
  landing_subtitle: '以下的資訊僅會用於本活動相關作業上',
  landing_affiliate: '盟友',
  landing_partner: '夥伴',
  landing_full_name: '真實姓名',
  landing_email: '電子郵件',
  landing_contact: '聯絡電話',
  landing_country: '國家/地區',
  landing_address: '通訊地址',
  landing_address_info: '*以下資訊是用於寄送實體獎品。如果您計劃在接下來的三個月搬家，請在現在地址的後方加上「*計畫搬家」',
  landing_state: '區',
  landing_city: '城市',
  landing_marketing: '出於活動行銷目的，我們可能會在我們的溝通管道中推廣您的頻道內容。您是否同意授權 Twitch 使用您的肖像、影片/剪輯或實況內容進行宣傳？',
  landing_attention: '*注意：如果您違反社群規範、信任與安全條款或當地法律等，Twitch 有權刪除任何行銷資源',
  landing_agree: '我同意授權',
  landing_disagree: '我不同意授權',
  landing_submit: '送出',
  landing_success: '報名成功！你已經是活動一員啦！',
  landing_click_here: '點擊這裡',
  landing_success_info: '，到Glitch EXP網站查看你是否有本活動出現在Open Campaign。接下來，精心策劃有趣的活動，跟粉絲觀眾一起同樂吧',
  landing_error: '不好意思，您不符合本次活動資格 :(',
  landing_error_info: '雖然本活動為邀請制，我們會記下您的申請，未來若開放更多夥伴盟友參加時，會邀請您參加本活動。',
  unauthorized_message: '抱歉，您目前沒有存取權限。',
  dashboard_unused_overlay: '請在OBS使用EXP覆蓋層！'
}
